.upload-modal {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);

  .ant-modal {
    max-width: 100%;
    height: 100%;
    color: #ffffff;
    top: 0;
    padding: 0;
    margin: 0;
    left: 0;
    .ant-modal-header {
      background-color: transparent;
      border: none;

      .ant-modal-title {
        color: #ffffff;
        margin-right: 32px;

        a {
          color: #ffffff;

          &:hover {
            color: #cccccc;
          }
        }
      }
    }

    .ant-modal-close {
      color: #ffffff;
    }

    .ant-modal-content {
      height: 100%;
      background-color: transparent;
    }

    .ant-modal-body {
      max-height: calc(~'100% - 57px');
      height: calc(~'100% - 57px');
      background-color: transparent;
      padding: 0;
    }
  }
  .swiper-container{
    height: 100%;
  }
}
.reco-upload-item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;

  img {
    display: block;
    max-height: 80vh;
    max-width: 100%;
  }
}
