@import "mixins/height";

.ant-card.register-box {
  margin: 16px 0;
  min-height: calc(~'100vh - 375px');
  background: #ffffff url("../images/back.png") bottom center repeat-x;

  .ant-card-body {
    >.ant-row {
      margin: 16px 0;
    }
  }
}


.code-btn {
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  border: #d9d9d9 solid 1px;
  text-align: center;

  &:hover {
    color: @primary-color;
    border-color: @primary-color;
  }
}