.vertify-box {
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: 50%;
    height: 420px;
    width: 300px;
    margin-left: -150px;
    margin-top: -210px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 25px;
    display: none;
    z-index: 101;
}

.vertify-top-s {
    font-size: 12px;
    color: #ccc;
    display: block;
    text-align: center;
    margin-left: 25px;
    margin-top: 25px;
    margin-bottom: 5px;
}

.vertify-top-x {
    font-size: 18px;
    color: black;
    display: block;
    text-align: center;
    margin-bottom: 45px;
}

.vertify-cuo {
    float: right;
    margin-right: 10px;
    margin-top: 5px;
    cursor: pointer;
}

.vertify-background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: none;
}
