@charset "UTF-8";

/*tabs-policy*/
.ant-tabs.tabs-policy {
  .ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{
    margin-right: 8px;
  }
 
}
/*tabs-policy end*/


