.basics-card {
    position: relative;
    width: 100%;
    height: 105px;
    border-radius: 4px;
    font-size: 22px;
    font-weight: 400;
    color: #333333;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-items: start;
    cursor: pointer;
    &.active,
    &:hover {
        background: linear-gradient(0deg, rgba(152, 224, 95, 0.2) 0%, rgba(13, 227, 172, 0.19) 100%) !important;
        &:before {
            content: "";
            position: absolute;
            border: 2px solid #48d376;
            box-shadow: 0 6px 16px 0 rgba(4, 20, 42, 0.1);
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            border-radius: 4px;
        }
    }

    &:after {
        content: "";
        position: absolute;
        width: 88px;
        height: 88px;
        right: 30px;
    }
    &.card-1 {
        background: #f0faf2;
        &:after {
            background: url("../images/basics_1.png") no-repeat center;
            background-size: cover;
        }
    }
    &.card-2 {
        background: #ebf6fa;
        &:after {
            background: url("../images/basics_2.png") no-repeat center;
            background-size: cover;
        }
    }
    &.card-3 {
        background: #f0faf2;
        &:after {
            background: url("../images/basics_3.png") no-repeat center;
            background-size: cover;
        }
    }
    &.card-4 {
        background: #f0fcff;
        &:after {
            background: url("../images/basics_4.png") no-repeat center;
            background-size: cover;
        }
    }
}
