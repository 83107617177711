.loading-spin-box {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
    .ant-spin {
        color: @primary-color;
        background-color: #fff;
        padding: 24px;
        box-shadow: 0 6px 16px 0 rgba(14, 61, 124, 0.1);
        border-radius: 5px;
        .ant-spin-text {
            margin-top: 8px;
        }
    }
    .loading-close {
        position: absolute;
        right: 24px;
        top: 24px;
        font-size: 22px;
        color: #ffffff;
        cursor: pointer;
    }
}
