div.images-auto {
  object-fit: cover;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  height: calc(~'100vw / 1.77777');
  background: #f4f4f4 center no-repeat;
  background-size: cover;

  .am-badge {
    position: absolute;
    left: 0;
    top: 0;
  }
  > img{
    display: none;
    border: none;
  }
  .error-image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    max-width: 150px;
    max-height: 150px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.theme-white {
  div.images-auto {
    background-color: #FFFFFF;
  }
}
