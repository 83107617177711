@import "themes/default";

.extend-tag1{
    background-color: #0ed88a !important;
}
.extend-tag2{
    background-color: #0ebed8 !important;
}
.extend-tag3{
    background-color: #a25db8 !important;
}
.investment-card{
    .index-price-img{
        overflow: hidden;
        i{
            left: 0;
            top:0;
        }
        div.images-auto{
            transition: all 0.35s;
        }
    }
    &.ant-card-bordered{
        border-color:#dae0f4;
    }
    .ant-card-actions{
        border-top-color: #dae0f4;
        background: #f1f3fb;
        display: flex;
        li{
            flex: 1;
            margin: 0;
            padding: 12px 0;
            &:hover{
                background-color: #dae0f4;
            }
        }
    }
    &:hover{
        .index-price-img{
            div.images-auto{
                transform: scale(1.1);
            }
        }
    }
}
.resource-swiper{
    .swiper-pagination-bullet{
        border-radius: 4px;
    }
    .swiper-pagination-bullet-active{
        width: 24px;
        background-color: @primary-color;
    }
}
.resource-content{
    .swiper-button-prev,.swiper-button-next{
        width: 48px;
        height: 48px;
        background-color: @white-color;
        box-shadow: 0 4px 6px 0 rgba(38, 38, 38, 0.15);
        margin-top: 28px;
        transition: all 0.35s;
        &:after{
            font-size: 22px;
            color:@gray-color;
            transition: all 0.35s;
        }
        &:hover{
            background-color: @primary-color;
            &:after{
                color:@white-color;
            }
        }
    }
    .swiper-button-prev{
        left: -24px;
    }
    .swiper-button-next{
        right: -24px;
    }
}
.mating-item{
    height: 360px;
    border:#eaeff5 solid 1px;
    text-align: center;
    padding-top: 70px;
    cursor: pointer;
    transition: all 0.35s;
    &.item0{
        background: url("../images/shf/pt-bg1.png") no-repeat;
        background-size: cover;
    }
    &.item1{
        background: url("../images/shf/pt-bg2.png") no-repeat;
        background-size: cover;
    }
    &.item2{
        background: url("../images/shf/pt-bg3.png") no-repeat;
        background-size: cover;
    }
    &.item3{
        background: url("../images/shf/pt-bg4.png") no-repeat;
        background-size: cover;
    }
    &:hover{
        border-color:#c8daf0;
        box-shadow: 0 6px 27px 0 rgba(4, 71, 152, 0.14);
    }
}
.mating-item-icon{
    height: 70px;
    img{
        max-height: 100%;
    }
}
.mating-content{
    padding-bottom: 90px;
}
.guide-write{
    color:#5d5d5d;
    font-size: 19px;
    line-height: 2;
}
.indent{
    text-indent: 2em;
}
.guide-img{
    position: relative;
    padding: 0 0 20px 20px;
    &:after{
        content:"";
        position: absolute;
        left: 0;
        bottom:0;
        background-color: #f6f6f6;
        width: 416px;
        height: 360px;
    }
    div.images-auto{
        z-index: 2;
    }
}

.link-button {
    display: block;
    height: 48px;
    line-height: 46px;
    border: 1px solid #d5d5d5;
    font-size: 18px;
    text-align: center;
    color: @primary-color;
}

.policy-search {
    margin: 20px 0;

    .ant-input-search {
        .ant-input,
        .ant-btn {
            border-radius: 0;
            height: 48px;
            line-height: 48px;
            font-size: 18px;
        }
    }

    .ant-input {
        line-height: 26px;
    }

    .ant-btn {
        padding: 0 40px;

        .icon {
            font-size: 18px;
            margin-right: 10px;
        }

        &.find-house {
            background-color: #1c4ac0;
            padding: 0 20px;
        }
    }
}

.enterprise-tag {
    span {
        display: inline-block;
        background-color: #e8f1fc;
        color: @primary-color;
        height: 26px;
        line-height: 24px;
        font-size: 13px;
        padding: 0 10px;
        margin-right: 10px;
        border: @primary-color solid 1px;
        border-radius: 4px;
    }
}
.util-list{
    &.ant-list-lg .ant-list-item{
        padding: 16px 0;
    }
}

.map-type {
    position: absolute;
    left: 30px;
    top: 6px;
    width: 1000px;
    pointer-events: all;
    .ant-select-lg {
        font-size: 14px;
        color: #333;
    }
}

.map-list {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 400px;
    background-color: #fff;
    border-left: 1px solid #e8e8e8;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    pointer-events: all;
    .hd {
        padding: 15px;
        border-bottom: 1px solid #e8e8e8;
    }
    .bd {
        flex: 1;
        overflow: auto;
        padding: 15px;
    }
    .ant-list-pagination {
        text-align: center;
    }
    .list-bd {
        display: flex;
        .word {
            flex: 1;
        }
        img {
            display: block;
            width: 100px;
            height: 84px;
            margin-right: 15px;
        }
        .title {
            font-size: 18px;
            color: #1f71e0;
        }
        .text1 {
            font-size: 14px;
            color: #666;
        }
    }
}
.marker-img {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    width: 15px;
    height: 20px;
    .marker-box {
        position: absolute;
        left: 32px;
        top: -20px;
        width: 428px;
        padding: 30px;
        background-color: #fff;
        box-shadow: 0px 0px 15px 0px rgba(91, 91, 91, 0.15);
        display: none;
        border-radius: 8px;
        &:before {
            content: "";
            position: absolute;
            left: -8px;
            top: 27px;
            display: inline-block;
            width: 0;
            height: 0;
            border-top: 8px solid transparent;
            border-right: 8px solid #fff;
            border-bottom: 8px solid transparent;
        }
        .hd {
            display: flex;
            > img {
                display: block;
                width: 137px;
                height: 84px;
                margin-right: 15px;
            }
            .word {
                flex: 1;
            }
            .title {
                font-size: 18px;
                color: #1f71e0;
            }
            .text1 {
                padding: 5px 0;
                font-size: 14px;
                color: #666;
            }
        }
        .xian {
            display: block;
            height: 1px;
            border-bottom: 1px dashed #e5e5e5;
            margin: 15px 0;
        }
        .adds {
            font-size: 16px;
            color: #666;
            margin-top: 10px;
        }
        .tag {
            position: relative;
            span {
                display: inline-block;
                height: 24px;
                line-height: 22px;
                padding: 0 15px;
                border: 1px solid rgb(31, 113, 224);
                border-radius: 4px;
                background-color: #e8f1fc;
                font-size: 13px;
                color: #1f71e0;
                margin-right: 10px;
            }
        }
        .more {
            display: block;
            border-radius: 5px;
            background-color: rgb(31, 113, 224);
            height: 41px;
            line-height: 41px;
            color: #fff;
            font-size: 16px;
            width: 135px;
            margin: 20px auto 0 auto;
            text-align: center;
        }
        .row {
            display: flex;
            color: #666;
            line-height: 1.8;
            margin: 5px 0;
            .lab {
                display: inline-block;
                width: 75px;
                font-size: 16px;
            }
            .text {
                flex: 1;
                font-size: 16px;
                color: #666;
            }
        }
    }
    .aboutus-name {
        position: absolute;
        left: -64px;
        bottom: 28px;
        width: 150px;
        padding: 10px;
        background-color: #fff;
        box-shadow: 0px 0px 15px 0px rgba(91, 91, 91, 0.15);
        border-radius: 8px;
        display: none;
        &:before {
            content: "";
            position: absolute;
            left: 68px;
            bottom: -8px;
            display: inline-block;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-top: 8px solid #fff;
            border-right: 8px solid transparent;
        }
    }
    &:hover {
        .marker-box {
            display: block;
        }
        .aboutus-name {
            display: block;
        }
    }
}

.list-link {
    display: block;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    border-radius: 4px;
    color: #333;
    font-size: 14px;
    & > span {
        position: relative;
        top: 1px;
        margin-right: 5px;
        font-size: 18px;
        color: #1f71e0;
    }
}

.one-screen {
    height: calc(100vh - 53px);
}

.map-box {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.map-park-infos {
    position: absolute;
    width: 436px;
    height: 552px;
    background-color: #fff;
    z-index: 9;
    // display: none;
    top: 100px;
    left: 30px;
    pointer-events: all;

    .map-park-title {
        position: relative;
        overflow: hidden;
        padding: 0 30px;
        background-color: #4c6ad7;
        line-height: 62px;
        height: 62px;
        font-size: 22px;
        color: #fff;

        .map-park-title-name {
            width: 346px;
            overflow: hidden;
            white-space: nowrap; // 防止多个单词换行
            text-overflow: ellipsis;
        }
    }

    .map-park-content {
        position: relative;
        overflow: hidden;
        padding: 0 20px;
    }
}

.marker-img-info {
    .marker-box {
        padding: 30px;

        &:before {
            content: "";
            position: absolute;
            left: -8px;
            top: 27px;
            display: inline-block;
            width: 0;
            height: 0;
            border-top: 8px solid transparent;
            border-right: 8px solid #fff;
            border-bottom: 8px solid transparent;
        }

        .hd {
            display: flex;

            > img {
                display: block;
                width: 137px;
                height: 84px;
                margin-right: 15px;
            }

            .word {
                flex: 1;
            }

            .title {
                font-size: 18px;
                color: #1f71e0;
            }

            .text1 {
                padding: 5px 0;
                font-size: 14px;
                color: #666;
            }
        }

        .xian {
            display: block;
            height: 1px;
            border-bottom: 1px dashed #e5e5e5;
            margin: 15px 0;
        }

        .adds {
            font-size: 16px;
            color: #666;
            margin-top: 10px;
        }

        .tag {
            position: relative;

            span {
                display: inline-block;
                height: 24px;
                line-height: 22px;
                padding: 0 15px;
                border: 1px solid #4c6ad7;
                border-radius: 4px;
                background-color: #e8f1fc;
                font-size: 13px;
                color: @primary-color;
                margin-right: 10px;
            }
        }

        .more {
            display: block;
            border-radius: 5px;
            background-color: #4c6ad7;
            height: 41px;
            line-height: 41px;
            color: #fff;
            font-size: 16px;
            width: 135px;
            margin: 20px auto 0 auto;
            text-align: center;
            cursor: pointer;
            &:hover{
                background-color: @primary-color;
            }
        }

        .row {
            display: flex;
            color: #666;
            line-height: 1.8;
            margin: 5px 0;

            .lab {
                display: inline-block;
                width: 75px;
                font-size: 16px;
            }

            .text {
                flex: 1;
                font-size: 16px;
                color: #666;
            }
        }
    }

    .aboutus-name {
        position: absolute;
        padding: 10px;
        background-color: #fff;
        box-shadow: 0px 0px 15px 0px rgba(91, 91, 91, 0.15);
        border-radius: 8px;
        display: none;

        &:before {
            content: "";
            position: absolute;
            left: 68px;
            bottom: -8px;
            display: inline-block;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-top: 8px solid #fff;
            border-right: 8px solid transparent;
        }
    }

    &:hover {
        .marker-box {
            display: block;
        }

        .aboutus-name {
            display: block;
        }
    }
}
.satellite-btn{
    position: absolute;
    right:15px;
    top:15px;
    background-color: @primaryHover-color;
    padding: 0 12px;
    height: 26px;
    line-height: 26px;
    color:@white-color;
    border-radius: 2px;
    z-index: 10;
    cursor: pointer;
    font-size: 13px;
    &:hover{
        background-color: @primary-color;
    }
}
