@import "themes/default";

.nav {
    background-color: @white-color;
    height: 84px;
    line-height: 28px;
    //color: @nav-color;
    font-size: 12px;
    position: relative;
    box-shadow: 0 6px 20px 0 rgba(35, 35, 35, 0.08);
    display: flex;
    align-items: center;
    transition: 0.2s all;
    .ant-badge-not-a-wrapper:not(.ant-badge-status) {
        vertical-align: middle !important;
    }
    .content-left {
        margin-top: 20px;
    }
    .title {
        font-size: 26px;
        font-weight: 500;
        color: #000000;
        margin-left: 18px;
    }
    .interval {
        margin: 0 7px 0 18px;
        font-size: 20px;
        line-height: 1;
        height: 20px;
        background: unset;
    }
    .pull-left {
        display: flex;
        align-items: center;
    }

    a {
        color: #333333;
        text-decoration: none;
        font-size: 14px;
        display: flex;
        vertical-align: bottom;
        align-items: center;
        justify-content: center;
        &:hover {
            color: @gray-four-color;
        }
    }
}

.head-logo {
    // height: 45px;
    height: 32px;
    float: left;
    margin-right: 30px;

    img {
        height: 100%;
    }
}

.header {
    background-color: @primary-color;
    transition: all 0.2s;

    .ant-menu-horizontal {
        border-bottom: none;
        line-height: 50px;
    }
    .search-box {
        transition: all 0.3s;
        width: 160px;
        height: 30px;
        border-radius: 41px;
        &.active {
            width: 250px;
        }
        i {
            color: #000000;
        }
        .anticon {
            display: none;
        }
    }
    .container {
        .ant-menu {
            display: flex;
            width: 100%;
            background-color: transparent;
            .ant-menu-item {
                padding: 0;
                //margin: 0 25px;
                text-align: center;
                flex: 1;
                border: none !important;
                > a {
                    color: @white-color;
                }
                &.ant-menu-item-selected{
                    a{
                        color:#c8974c;
                    }
                }
            }
            .ant-menu-submenu {
                padding: 0 25px;
                margin: 0;
                border: none;
                flex: 1;
                text-align: center;
                &::before {
                    content: "\E680";
                    position: absolute;
                    right: 26px;
                    color: rgba(174,142,105,0.8);
                    font-family: "icon" !important;
                }
                &.ant-menu-submenu-selected{
                    span{
                        color:#c8974c;
                    }
                }
                span {
                    color: @white-color;
                    margin-left: 5px;
                }
            }
            .ant-menu-submenu {
                border: none !important;
            }
        }
    }

    .ant-menu {
        li {
            float: right;
            &.ant-menu-item-selected,
            &.ant-menu-submenu-selected,
            &.ant-menu-submenu-active {
                &::after {
                    content: "";
                    position: absolute;
                    width: 18px;
                    height: 4px;
                    background-color: @primary-color;
                    border-radius: 4px;
                    bottom: 6px;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            &:hover {
                & > a {
                    color: #c8974c !important;
                }

                .ant-menu-submenu-title {
                    color: #c8974c !important;
                    border: none;
                }
                &::after {
                    content: "";
                    position: absolute;
                    width: 18px;
                    height: 4px;
                    background-color: @primary-color;
                    border-radius: 4px;
                    bottom: 6px;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}

.global-search {
    width: 250px;

    &.ant-select-auto-complete .ant-input-affix-wrapper .ant-input:not(:last-child) {
        padding-right: 62px;
    }

    &.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix {
        right: 0;
    }

    &.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .global-search-item-count {
        position: absolute;
        right: 16px;
    }
}
.ipark-menu{
    .ant-menu-vertical.ant-menu-sub{
     min-width: unset !important;
        margin-left: 26px;
        .ant-menu-item{
            font-size: 16px;
        }
    }
}

.head-menu {
    z-index: 999;
    position: relative;
    &.hide{
        .Affix{
            position: fixed;
            width: 100%;
            z-index: 9;
        }
        .ant-affix{
            height: auto !important;
        }
        .header {
            box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.05);
        }
        .nav{
            margin-top: -83px;
        }
    }

}

.head-menu {
    position: relative;
    overflow: hidden;
    z-index: 100;
}

.header-search {
    height: 32px;
    line-height: 32px;
    padding-right: 20px;
    // &:hover {
    //   padding-left: 180px;
    //  input{
    //   display: inline-block;
    //   // background-color: @primary-color;
    //   opacity: 1;
    //   width: 180px;
    //  }
    // }
    input {
        width: 85%;
        // border: none;
        padding-right: 30px;
        // background-color: rgba(0, 0, 0, 0);
        // transition: all .3s ease-in-out;
        // border-radius: 10px;
        // opacity: 0;
    }

    i {
        font-size: 20px;
        margin-top: 6px;
        float: right;
        color: @primary-color;
        cursor: pointer;
    }
}
.gutter-example {
    .ant-menu-inline .ant-menu-item {
        font-size: 16px;
        color: #333;
        height: 64px;
        line-height: 64px;
    }
    .ant-menu-inline .ant-menu-item-selected {
        color: #fff;
        margin: 0 -10px;
        border-radius: 6px;
        box-shadow: 0 6px 8px 0 rgba(0, 106, 241, 0.36);
        width: calc(~"100% + 20px");
    }
}
.home-search {
    width: 218px;
    height: 30px;
    border: 1px solid #e0e0e1;
    border-radius: 15px;
    position: relative;
    padding-right: 64px;
    margin-right: 40px;
    background-color: #e0e0e1;
    input {
        border: none;
        outline: none;
        box-shadow: unset !important;
        background-color: transparent;
        padding-left: 15px;
    }
    a {
        width: 40px;
        height: 30px;
        background-color: #e0e0e1;
        border-radius: 15px;
        position: absolute;
        text-align: center;
        color: #999;
        right: 0;
        top: -1px;
    }
}
.content-right {
    display: flex;
}
