// 滑动选择组件自定义样式
@import "themes/default";

.meeting-view {
    ul {
        padding: 0;
        margin: 0;
        display: flex;
        border-right: #ddd solid 1px;
        border-bottom: #ddd solid 1px;
        li {
            list-style: none;
            flex: 1;
            height: 60px;
            position: relative;
            &.active {
                &:before {
                    content: "";
                    height: 70%;
                    width: 100%;
                    background-color: rgba(211, 211, 211, 0.4);
                    bottom: 0;
                    left: 0;
                    position: absolute;
                }
            }
            span {
                position: absolute;
                top: 0;
                left: 6px;
                font-size: 12px;
                color: #7f7f7f;
                line-height: 1.2;
            }

            &:after {
                content: "";
                position: absolute;
                height: 100%;
                width: 1px;
                left: 0;
                top: 0;
                background-color: #dcdcdc;
                z-index: 9;
            }

            &:nth-child(even) {
                &:after {
                    height: 70%;
                    top: 30%;
                }
            }
        }
    }
}

.range-scroll {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    transform: translate3d(0, 0, 0);
    padding-left: 12px;
    //pointer-events: none;
    .select-box {
        background-color: rgba(255, 255, 255, 1);
        height: 70px;
        position: absolute;
        top: 0;
        z-index: 1;
    }

    .irs-grid-text {
        margin-bottom: -20px;
        color: #999;
        top: 0;
        padding-left: 50px;
        z-index: 9;
        pointer-events: none;
        font-size: 12px;
    }
}

.range-slider-box {
    position: relative;

    &.error {
        .irs--flat .irs-bar {
            background-color: red;
        }

        .irs-handle.to {
            border: #ff000059 2px solid !important;
        }
    }

    .irs-handle.from {
        display: none;
    }

    .irs--flat.irs-with-grid {
        height: 95px;
    }

    .irs--flat .irs-line {
        height: 70px;
        position: absolute;
        left: 7px;
        right: 9px;
        background-color: transparent;
    }

    .irs--flat .irs-handle {
        top: 25px;
        height: 70px;
        margin-left: -2px;
    }

    .irs-bar,
    .irs-handle,
    .irs-to,
    .irs-from {
        // transition: all 0.2s;
        pointer-events: all;
        cursor: pointer !important;
    }

    .irs--flat .irs-grid-pol {
        background-color: #d3d5d8;
    }

    .irs-grid-pol {
        top: 0;
    }

    .irs-grid {
        height: 70px;
        top: 24px;
        //background-color: rgba(221, 221, 221, 0.6);
        &:after {
            content: "";
            height: 75%;
            left: 0;
            right: 0;
            top: 25%;
            background-color: rgba(221, 221, 221, 0.6);
            position: absolute;
        }

        .irs-grid-pol {
            &:nth-child(even) {
                height: 30px;
            }

            &.odd {
                height: 52px;
                top: 18px;
            }
        }
    }

    .irs-handle {
        &.type_last,
        &.to {
            height: 20px;
            width: 20px;
            background-color: #fff;
            border-radius: 50%;
            top: 70%;
            transform: translate(-25%, -50%);
            border: #096dd9 2px solid;
            z-index: 7;
            i {
                display: none !important;
            }
        }
    }

    .irs-grid-pol {
        height: 70px;
        z-index: 2;
    }

    //.irs--flat .irs-line {
    //  background-color: rgba(221,221,221,0.6);
    //  border-radius: 0;
    //}
    .irs-grid-pol {
        &:after {
            content: "";
            width: 40px;
            background-color: #dddddd;
            height: 40px;
        }
    }

    .irs--flat .irs-bar {
        background-color: @primary-color;
        top: 42px;
        height: 53px;
        opacity: 0.2;
        margin-left: -0.1%;
        z-index: 7;
    }

    .irs--flat .irs-handle > i:first-child {
        background-color: @primary-color;
        z-index: 2;
    }

    .irs--flat .irs-from,
    .irs--flat .irs-to,
    .irs--flat .irs-single {
        margin-left: -0.1%;
        font-size: 12px;
        background-color: @primary-color;
        z-index: 2;
    }

    .irs--flat .irs-from:before,
    .irs--flat .irs-to:before,
    .irs--flat .irs-single:before {
        border-top-color: @primary-color;
        z-index: 2;
    }
}
.hint {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
}
