@import "themes/default";
.footer{
  background-color: @primary-color;
  color: @white-color;
  padding: 30px 0;
  .ant-typography{
    color: #ffffff;
  }
  .footer-desc{
    margin-top: 10px;
    font-size: 14px;
  }
  .footer-content{
     margin-right: 30px;
     ul,li{
       margin: 0;
       padding: 0 0 5px;
       list-style: none;
     }
     a{
        color: @white-color;
       &:hover{
         color: @white-color;
       }
     }
  }
  .ant-col:last-child{
    .footer-content{
      margin-right: 0;
    }
  }
  .footer-desc{
    p{
      margin-bottom: 0;
      i{
        margin-right: 3px;
        &.icon-phone{
          margin-right: 6px;
        }
      }
    }
    padding-bottom: 15px;
  }
  a{
    color: @white-color;
    &:hover{
      color: @white-color;
    }
  }
}
