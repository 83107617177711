@import "themes/default";

.padding-0 {
  padding: 0 !important;
}

.margin-0 {
  margin: 0 !important;
}

.padding-xs {
  padding: @xs  !important;
}

.margin-xs {
  margin: @xs  !important;
}

.padding-sm {
  padding: @sm  !important;
}

.margin-sm {
  margin: @sm  !important;
}

.padding {
  padding: @defaultSize  !important;
}

.margin {
  margin: @defaultSize  !important;
}

.padding-lg {
  padding: @lg  !important;
}

.margin-lg {
  margin: @lg  !important;
}

.padding-xl {
  padding: @xl  !important;
}

.margin-xl {
  margin: @xl  !important;
}

.padding-h-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.margin-h-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.padding-h-xs {
  padding-left: @xs  !important;
  padding-right: @xs  !important;
}

.margin-h-xs {
  margin-left: @xs  !important;
  margin-right: @xs  !important;
}

.padding-h-sm {
  padding-left: @sm  !important;
  padding-right: @sm  !important;
}

.margin-h-sm {
  margin-left: @sm  !important;
  margin-right: @sm  !important;
}

.padding-h {
  padding-left: @defaultSize  !important;
  padding-right: @defaultSize  !important;
}

.margin-h {
  margin-left: @defaultSize  !important;
  margin-right: @defaultSize  !important;
}

.padding-h-lg {
  padding-left: @lg  !important;
  padding-right: @lg  !important;
}

.margin-h-lg {
  margin-left: @lg  !important;
  margin-right: @lg  !important;
}

.padding-h-xl {
  padding-left: @xl  !important;
  padding-right: @xl  !important;
}

.margin-h-xl {
  margin-left: @xl  !important;
  margin-right: @xl  !important;
}

.padding-v-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.margin-v-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.padding-v-xs {
  padding-top: @xs  !important;
  padding-bottom: @xs  !important;
}

.margin-v-xs {
  margin-top: @xs  !important;
  margin-bottom: @xs  !important;
}

.padding-v-sm {
  padding-top: @sm  !important;
  padding-bottom: @sm  !important;
}

.margin-v-sm {
  margin-top: @sm  !important;
  margin-bottom: @sm  !important;
}

.padding-v {
  padding-top: @defaultSize  !important;
  padding-bottom: @defaultSize  !important;
}

.margin-v {
  margin-top: @defaultSize  !important;
  margin-bottom: @defaultSize  !important;
}

.padding-v-lg {
  padding-top: @lg  !important;
  padding-bottom: @lg  !important;
}

.margin-v-lg {
  margin-top: @lg  !important;
  margin-bottom: @lg  !important;
}

.padding-v-xl {
  padding-top: @xl  !important;
  padding-bottom: @xl  !important;
}

.margin-v-xl {
  margin-top: @xl  !important;
  margin-bottom: @xl  !important;
}

.padding-top-0 {
  padding-top: 0 !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.padding-top-xs {
  padding-top: @xs  !important;
}

.margin-top-xs {
  margin-top: @xs  !important;
}

.padding-top-sm {
  padding-top: @sm  !important;
}

.margin-top-sm {
  margin-top: @sm  !important;
}

.padding-top {
  padding-top: @defaultSize  !important;
}

.margin-top {
  margin-top: @defaultSize  !important;
}

.padding-top-lg {
  padding-top: @lg  !important;
}

.margin-top-lg {
  margin-top: @lg  !important;
}

.padding-top-xl {
  padding-top: @xl  !important;
}

.margin-top-xl {
  margin-top: @xl  !important;
}

.padding-right-0 {
  padding-right: 0 !important;
}

.margin-right-0 {
  margin-right: 0 !important;
}

.padding-right-xs {
  padding-right: @xs  !important;
}

.margin-right-xs {
  margin-right: @xs  !important;
}

.padding-right-sm {
  padding-right: @sm  !important;
}

.margin-right-sm {
  margin-right: @sm  !important;
}

.padding-right {
  padding-right: @defaultSize  !important;
}

.margin-right {
  margin-right: @defaultSize  !important;
}

.padding-right-lg {
  padding-right: @lg  !important;
}

.margin-right-lg {
  margin-right: @lg  !important;
}

.padding-right-xl {
  padding-right: @xl  !important;
}

.margin-right-xl {
  margin-right: @xl  !important;
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.padding-bottom-xs {
  padding-bottom: @xs  !important;
}

.margin-bottom-xs {
  margin-bottom: @xs  !important;
}

.padding-bottom-sm {
  padding-bottom: @sm  !important;
}

.margin-bottom-sm {
  margin-bottom: @sm  !important;
}

.padding-bottom {
  padding-bottom: @defaultSize  !important;
}

.margin-bottom {
  margin-bottom: @defaultSize  !important;
}

.padding-bottom-lg {
  padding-bottom: @lg  !important;
}

.margin-bottom-lg {
  margin-bottom: @lg  !important;
}

.padding-bottom-xl {
  padding-bottom: @xl  !important;
}

.margin-bottom-xl {
  margin-bottom: @xl  !important;
}

.padding-left-0 {
  padding-left: 0 !important;
}

.margin-left-0 {
  margin-left: 0 !important;
}

.padding-left-xs {
  padding-left: @xs  !important;
}

.margin-left-xs {
  margin-left: @xs  !important;
}

.padding-left-sm {
  padding-left: @sm  !important;
}

.margin-left-sm {
  margin-left: @sm  !important;
}

.padding-left {
  padding-left: @defaultSize  !important;
}

.margin-left {
  margin-left: @defaultSize  !important;
}

.padding-left-lg {
  padding-left: @lg  !important;
}

.margin-left-lg {
  margin-left: @lg  !important;
}

.padding-left-xl {
  padding-left: @xl  !important;
}

.margin-left-xl {
  margin-left: @xl  !important;
}


.blank-xs {
  position: relative;
  overflow: hidden;
  height: @xs;
}
.blank-sm {
  position: relative;
  overflow: hidden;
  height: @sm;
}
.blank {
  position: relative;
  overflow: hidden;
  height: @defaultSize;
}
.blank-lg {
  position: relative;
  overflow: hidden;
  height: @lg;
}
.blank-xl {
  position: relative;
  overflow: hidden;
  height: @xl;
}
/*1-50循环*/
.space(@count) when (@count >=0) {
  .space((@count - 1));

  .mt@{count} {
    margin-top: (1px * @count) !important;
  }

  .mr@{count} {
    margin-right: (1px * @count) !important;
  }

  .mb@{count} {
    margin-bottom: (1px * @count) !important;
  }

  .ml@{count} {
    margin-left: (1px * @count) !important;
  }

  .mg@{count} {
    margin: (1px * @count) !important;
  }

  .pt@{count} {
    padding-top: (1px * @count) !important;
  }

  .pr@{count} {
    padding-right: (1px * @count) !important;
  }

  .pb@{count} {
    padding-bottom: (1px * @count) !important;
  }

  .pl@{count} {
    padding-left: (1px * @count) !important;
  }

  .pg@{count} {
    padding: (1px * @count) !important;
  }
  .pv@{count} {
    padding-top: (1px * @count) !important;
    padding-bottom: (1px * @count) !important;
  }
  .mv@{count} {
    margin-top: (1px * @count) !important;
    margin-bottom: (1px * @count) !important;
  }
  .ph@{count}{
    padding-left: (1px * @count) !important;
    padding-right: (1px * @count) !important;
  }
  .mh@{count}{
    margin-left: (1px * @count) !important;
    margin-right: (1px * @count) !important;
  }
  .blank@{count} {
    position: relative;
    overflow: hidden;
    height: (1px * @count) !important;
  }
}

.space(60);
/*1-50循环end*/